export const footerConfig = {
  links: [
    { url: 'https://www.vermieter.immomio.com/agb', name: 'footer.terms_a' },
    {
      url: 'https://www.vermieter.immomio.com/datenschutz',
      name: 'footer.data_protection_a'
    },
    {
      url: 'https://www.vermieter.immomio.com/impressum',
      name: 'footer.imprint_a'
    }
  ]
};
