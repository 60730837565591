export const notificationConfig = {
  inventory: {
    fetch: {
      error: 'IMPORT.LOG.FAIL.L'
    }
  },

  payments: {
    add: {
      error: 'PAYMENT.METHOD.ADD.FAIL.L',
      success: 'PAYMENT.METHOD.ADD.SUCCESS.L'
    }
  },

  subscriptions: {
    update: {
      error: 'SUBSCRIPTION.UPDATE.FAIL.L',
      success: 'SUBSCRIPTION.UPDATE.SUCCESS.L'
    },
    updateNextProduct: {
      error: 'subscription.update_next_product_fail_l',
      success: 'subscription.update_next_product_success_l'
    }
  },

  landlord: {
    save: {
      success: 'landlord.save_success_m'
    },
    create: {
      error: 'landlord.create_error_m'
    }
  },

  objectHierarchy: {
    action: {
      success: 'admin.object_hierarchy.action_completed_successful_m'
    }
  },

  agents: {
    add: {
      error: 'AGENT.ADD.FAIL.L',
      success: 'AGENT.ADD.SUCCESS.L'
    },
    update: {
      error: 'AGENT.UPDATE.FAIL.L',
      success: 'AGENT.UPDATE.SUCCESS.L'
    },
    remove: {
      error: 'AGENT.REMOVE.FAIL.L',
      success: 'AGENT.REMOVE.SUCCESS.L'
    },
    fetch: {
      error: 'AGENT.FETCH.FAIL.L',
      success: 'AGENT.FETCH.SUCCESS.L'
    }
  },

  property: {
    add: {
      error: 'PROPERTY.ADD.FAIL.L',
      success: 'PROPERTY.ADD.SUCCESS.L'
    },
    update: {
      error: 'PROPERTY.UPDATE.FAIL.L',
      success: 'PROPERTY.UPDATE.SUCCESS.L'
    },
    remove: {
      error: 'PROPERTY.REMOVE.FAIL.L',
      success: 'PROPERTY.REMOVE.SUCCESS.L'
    },
    fetch: {
      error: 'PROPERTY.FETCH.FAIL.L',
      success: 'PROPERTY.FETCH.SUCCESS.L'
    },
    publish: {
      error: 'PROPERTY.PUBLISH.FAIL.L',
      success: 'PROPERTY.PUBLISH.SUCCESS.L'
    },
    deactivate: {
      error: 'PROPERTY.DEACTIVATE.FAIL.L',
      success: 'PROPERTY.DEACTIVATE.SUCCESS.L'
    }
  },

  propertySearcher: {
    remove: {
      error: 'PROPERTY.SEARCHER.REMOVE.FAIL.L',
      success: 'PROPERTY.SEARCHER.REMOVE.SUCCESS.L'
    },
    reject: {
      error: 'PROPERTY.SEARCHER.REJECT.FAIL.L',
      success: 'PROPERTY.SEARCHER.REJECT.SUCCESS.L'
    },
    fetch: {
      error: 'PROPERTY.SEARCHER.FETCH.FAIL.L',
      success: 'PROPERTY.SEARCHER.FETCH.SUCCESS.L'
    },
    makeTenant: {
      error: 'APPLICANTS.MAKE.TENANT.FAIL.M'
    },
    offerFlatToProspect: {
      error: 'PROSPECT.INVITE.FAIL.M'
    },
    acceptApplicant: {
      error: 'APPLICANTS.ACCEPT.FAIL.M'
    },
    rejectApplicant: {
      error: 'APPLICANTS.REJECT.FAIL.M'
    }
  },

  prioset: {
    add: {
      error: 'PRIOSET.ADD.FAIL.L',
      success: 'PRIOSET.ADD.SUCCESS.L'
    },
    update: {
      error: 'PRIOSET.UPDATE.FAIL.L',
      success: 'PRIOSET.UPDATE.SUCCESS.L'
    },
    remove: {
      error: 'PRIOSET.REMOVE.FAIL.L',
      success: 'PRIOSET.REMOVE.SUCCESS.L'
    },
    fetch: {
      error: 'PRIOSET.FETCH.FAIL.L',
      success: 'PRIOSET.FETCH.SUCCESS.L'
    }
  },

  user: {
    update: {
      error: 'USER.UPDATE.FAIL.L',
      success: 'USER.UPDATE.SUCCESS.L'
    },
    activate: {
      error: 'user.activate_fail_l',
      success: 'user.activate_success_l'
    },
    impersonate: {
      error: 'user.impersonate_fail_l',
      success: 'user.impersonate_success_l'
    },
    delete: {
      error: 'user.delete_fail_l',
      success: 'user.delete_success_l'
    }
  },

  customer: {
    update: {
      error: 'CUSTOMER.UPDATE.FAIL.L',
      success: 'CUSTOMER.UPDATE.SUCCESS.L'
    },
    disable: {
      error: 'customer.disable_fail_l',
      success: 'customer.disable_success_l'
    },
    delete: {
      error: 'customer.delete_fail_l',
      success: 'customer.delete_success_l'
    },
    enableExternalPricing: {
      error: 'customer.external_pricing_error_l',
      enableSuccess: 'customer.enable_external_pricing_success_l',
      disableSuccess: 'customer.disable_external_pricing_success_l'
    }
  },

  emailTemplateTypes: {
    fetch: {
      error: 'EMAIL.TEMPLATE.TYPES.FETCH.FAIL.L'
    }
  },

  emailTemplate: {
    fetch: {
      error: 'EMAIL.TEMPLATE.FETCH.FAIL.L'
    }
  },

  messageSources: {
    update: {
      success: 'MESSAGE.SOURCES.UPDATE.SUCCESS.L',
      error: 'MESSAGE.SOURCES.UPDATE.FAIL.L'
    }
  },

  search: {
    notFound: 'general.not_found_m'
  },

  invoice: {
    cancel: {
      success: 'invoice.cancel_success_m',
      error: 'invoice.cancel_fail_m'
    }
  },
  digital_contract: {
    save: {
      success: 'digital_contract.save_success_m',
      error: 'digital_contract.save_error_m'
    }
  },
  object_contingent: {
    save: {
      success: 'object_contingent.save_success',
      error: 'object_contingent.save__error'
    }
  }
};
